.work {
    margin-top: 150px;
}
.work-title h1 {
    font-size: 55px;
    font-weight: 500;
    color: #fff;
    width: fit-content;
    margin: 0 auto;
}
.work-title h1 span {
    background-color: #000;
}
.work-title p {
    text-align: center;
    width: fit-content;
    margin: 15px auto;
    background-color: #fff;
}
.work-chips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    margin-top: 35px;
}

.chip {
    user-select: none;
    background-color: var(--color-secondary);
    padding: 5px 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition-duration: var(--transition-duration);
    font-size: 14px;
}
.chip .amount {
    margin-left: 5px;
}
.chip.active {
    background-color: #000;
    color: #fff;
}
.chip:hover {
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
}

.work-showcase {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 50px;
    column-gap: 20px;
    margin-top: 50px;
}

.work-card {
    max-width: 250px;
    flex-grow: 1;
    background-color: #fff;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--color-secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius);
    transition-duration: var(--transition-duration);
}
.work-card:hover {
    /* box-shadow: var(--box-shadow); */
    border-color: #000;
}
.work-card .work-card-image {
    /* background-color: var(--color-secondary); */
    width: 100%;
    border-radius: var(--border-radius);
}
.work-card-image-wrapper {
    width: 100%;
    height: 190px;
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-secondary);
}
.work-card .work-card-image img {
    object-fit: cover;
    object-position: top;
    width: 250px;
    height: 100%;
    filter: drop-shadow(var(--box-shadow-sm));
    /* border-radius: var(--border-radius); */
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
    max-width: fill-available;
    margin-bottom: 0px;
    display: flex;
}

.work-card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 5px;
}
.work-card-actions .btn {
    flex-grow: 1;
}

.work-card-stack {
    display: flex;
    gap: 5px;
    align-items: center;
}

.work-card h3 {
    margin: 10px 0;
    font-weight: 500;
}

.work .dots {
    padding: 10px 8px;
}

.work .window-dot-1,
.work .window-dot-2,
.work .window-dot-3 {
    width: 6px;
    height: 6px;
}

.work-card-deleted {
    color: #f41616;
    padding: 10px;
    text-align: center;
    width: 100%;
}

@media (max-width: 768px) {
    .work-card {
        max-width: none;
        width: 100%;
    }
    .work-card .work-card-image img {
        width: 230px;
    }
    .work-card-image-wrapper {
        padding-top: 20px;
    }
}