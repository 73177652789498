nav {
    padding: 20px 0;
}

nav ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
}

nav ul li {
    list-style: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: 600;
    transition-duration: var(--transition-duration);
}
nav ul a {
    text-decoration: none;
    color: var(--text-dark);
}
nav ul a::after {
    content: "";
    display: block;
    height: 2px;
    width: 0;
    background-color: #000;
    transition-duration: var(--transition-duration);
    border-radius: var(--border-radius);
}
nav ul a.active::after,
nav ul a:hover::after {
    width: 100%;
}

nav ul li:hover {
    opacity: 0.7;
}
nav ul li:active {
    opacity: 1;
}

@media (max-width: 768px) {
    nav ul {
        justify-content: space-around;
    }
}