:root {
  --color-main: #ffffff;
  --color-secondary: #f6f7f8;
  --color-tertiary: #cccccc;
  --color-primary: #56bbff;
  --color-border: #2E2E3B;

  --text-light: #ffffff;
  --text-dark: #2E2E3B;
  --box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  --box-shadow-sm: 0 10px 20px rgb(0 0 0 / 10%);
  
  --transition-duration: .3s;

  --border-radius: 14px;
  --border-radius-sm: 4px;
  --color-warning: #ffc956;
  --color-danger: #ff5e59;
  --color-success: #55f16c;
}

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

img {
  user-select: none;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #060606;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #000;
  border-radius: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');

html {
  overflow-x: hidden;
  /* scroll-behavior: smooth; */
}
body {
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #000000de 1px, rgba(0, 0, 0, 0) 1px);
  margin: 0;
  font-family: Rubik,sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  min-height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.blink {
  animation: blink 1s linear infinite;
}

.divider {
  height: 450px;
  transform: skew(0deg, -2deg);
  position: relative;
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #ffffffde 1px, rgba(0, 0, 0, 0) 1px);
  background-color: #060606;
  box-shadow: var(--box-shadow-sm);
}

.divider-minus-body {
  margin-top: -50px;
}
.divider-minus {
  margin-top: -250px;
}

.text-cursor {
  width: 5px;
  height: 100%;
  margin-left: 5px;
  position: relative;
  background-color: #000
}
.text-cursor-light {
  width: 5px;
  height: 20px;
  margin-left: 5px;
  position: relative;
  background-color: #fff
}
.flex {
  display: flex;
}
.mt-100 {
  margin-top: 100px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-5 {
  margin-top: 5px;
}

.btn {
  cursor: pointer;
  background-color: #060606;
  border-radius: var(--border-radius-sm);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 2px solid #060606;
  fill: #fff;
  transition-duration: var(--transition-duration);
  text-decoration: none;
  user-select: none;
}
.btn:active {
  opacity: .8;
}
.btn span {
  margin-right: 5px;
  display: flex;
}
.btn:hover {
  background-color: transparent;
  color: #000;
  fill: #000;
}

.btn.btn-link {
  background-color: transparent;
  border-color: transparent;
  fill: #000;
  color: #000;
}
.btn.btn-link:hover {
  background-color: #060606;
  border-color: #060606;
  color: #fff;
  fill: #fff;
}

.bg-white {
  background-color: #fff;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #060606;
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #ffffffde 1px, rgba(0, 0, 0, 0) 1px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition-duration: var(--transition-duration);
}
.splash-screen-content {
  font-size: 50px;
  color: #fff;
}
.splash-screen.hide {
  opacity: 0;
  pointer-events: none;
}

.opacity-0 {
  opacity: 0;
}

.min-h-100-vh {
  min-height: 100vh;
}
.min-h-50-vh {
  min-height: 50vh;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 8px;
  }
  .divider-minus {
    margin-top: -375px;
  }
}