footer {
    position: relative;
    background-size: 40px 40px;
    background-image: radial-gradient(circle, #ffffffde 1px, rgba(0, 0, 0, 0) 1px);
    background-color: #060606;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    margin-top: 150px;
}
.footer {
    padding-top: 150px;
}

footer h1 {
    font-size: 55px;
    font-weight: 500;
    color: #000;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}
footer h1 span {
    background-color: #fff;
}
footer h1 .blink {
    background-color: #fff;
}

.footer .contacts {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    gap: 50px;
    justify-content: center;
    align-items: center;
}
.footer .email {
    color: #fff;
    text-decoration: none;
    font-size: 30px;
    border-radius: var(--border-radius-sm);
}
.footer .socials {
    display: flex;
    gap: 41px;
    justify-content: space-between;
}
.footer .socials a {
    fill: #fff;
    color: #fff;
    width: 30px;
    height: 30px;
}
.footer .socials svg {
    width: 100%;
    height: 100%;
}
.footer a {
    background-color: #060606;
    padding: 2px;
    border: 2px solid transparent;
    border-radius: var(--border-radius-sm);
    transition-duration: var(--transition-duration);
    cursor: pointer;
}
.footer a:hover {
    border-color: #fff;
}
.footer a:active {
    opacity: .8;
}
.footer-bottom {
    color: #fff;
    text-align: center;
    padding-bottom: 10px;
    background-color: black;
    width: fit-content;
    margin: 150px auto 0 auto;
}