.header {
    display: flex;
    justify-content: end;
    gap: 1rem;
    align-items: center;
    padding-top: 5vh;
    min-height: 400px;
    position: relative;
}

.header .photo {
    width: 450px;
    /* border-radius: 50%; */
    position: relative;
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 0;
}
.header .photo .photo-bg {
    background: linear-gradient(to bottom, transparent 0%, rgb(0, 0, 0, 0.85) 50%);
    width: 100%;
    height: 150px;
    bottom: 0;
    position: absolute;
}
.header .photo img {
    width: 100%;
    /* border-bottom-left-radius: 15%;
    border-bottom-right-radius: 15%; */
    /* filter: drop-shadow(0 0 0.5rem #000); */
    border: 4px solid black;
    border-radius: var(--border-radius);
    filter: grayscale(0.85);
    transition-duration: var(--transition-duration);
    height: 100%;
}
.header .photo:hover img {
    filter: grayscale(0);
}
.header h2 .job {
    background-color: var(--text-dark);
    color: var(--text-light);
}

.info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 2;
    padding-bottom: 50px;
}

.arrow-item {
    display: flex;
    align-content: center;
    gap: 8px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        justify-content: start;
        min-height: 80vh;
    }
    .header .photo {
        width: 300px;
        order: 2;
        bottom: 35px;
        margin: 0 auto;
        left: unset;
    }
    .info {
        order: 1;
        margin-bottom: 50px;
    }
}